import 'bootstrap/dist/css/bootstrap.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";

function LandingPage() {
    let navigate = useNavigate();

    return (
        <Card>
            <Card.Body style={{"color": "black"}}>
                <div className="page-content" id="landingPage">
                    <p>Wilkommen bei deinem persönlichen Kursempfehlungssystem</p>
                    <p>Hier erhälst du anhand deiner bisherigen Studienergebnisse eine Empfehlung an Kursen die du im kommenden Semester belegen solltest.</p>
                </div>
            </Card.Body>
            <Card.Footer>
                <Button variant="primary" onClick={() => navigate('/upload')}>Start</Button>
            </Card.Footer>
        </Card>

    )
}

export default LandingPage
