import './App.css';
import { Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import LandingPage from './react/pages/LandingPage';
import UploadPage from './react/pages/UploadPage';
import RecommendationPage from './react/pages/RecommendationPage';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<LandingPage></LandingPage>}></Route>
            <Route path='/upload' element={<UploadPage></UploadPage>}></Route>
            <Route path='/recommendation' element={<RecommendationPage></RecommendationPage>}></Route>
            <Route render={() => <Navigate to="/" />}></Route>
          </Routes>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
