import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon';

function SusFormWidget() {
    const [showSusForm, setShowSusForm] = useState(false);
    const handleCloseSusForm = () => setShowSusForm(false);
    const [frequency, setFrequency] = useState(1);
    const [complexity, setComplexity] = useState(1);
    const [easeOfUse, setEaseOfUse] = useState(1);
    const [technicalSupport, setTechnicalSupport] = useState(1);
    const [integration, setIntegration] = useState(1);
    const [inconsistency, setInconsistency] = useState(1);
    const [learnability, setLearnability] = useState(1);
    const [cumbersome, setCumbersome] = useState(1);
    const [confidence, setConfidence] = useState(1);
    const [preparationNeeded, setPreparationNeeded] = useState(1);

    const handleFrequencyChange = input => setFrequency(input);
    const handleComplexityChange = input => setComplexity(input);
    const handleEaseOfUseChange = input => setEaseOfUse(input);
    const handleTechnicalSupportChange = input => setTechnicalSupport(input);
    const handleIntegrationChange = input => setIntegration(input);
    const handleInconsistencyChange = input => setInconsistency(input);
    const handleLearnabilityChange = input => setLearnability(input);
    const handleCumbersomeChange = input => setCumbersome(input);
    const handleConfidenceChange = input => setConfidence(input);
    const handlePreparationNeededChange = input => setPreparationNeeded(input);

    const semester = useSelector(state => state.recommendation.semester);
    const recommendationOverview = useSelector(state => state.recommendation.recommendationOverview);
    const recommendedModules = recommendationOverview.recommended_modules !== undefined ? recommendationOverview.recommended_modules : [];
    const program = recommendationOverview.program

    function handleSubmission(e) {
        e.preventDefault();

        const formData = new FormData(e.target);
        formData.append('semester', 'semester='+semester)

        // Convert recommendedModules to a string separeted with '-'
        const recommendedModulesString = recommendedModules.join('-');
        formData.append('recommendedModules', recommendedModulesString)
        formData.append('program', program);
        
        const timeZone = 'Europe/Berlin';
        const currentTime = DateTime.now().setZone(timeZone);
        const formattedTime = currentTime.toFormat('yyyy-MM-dd HH:mm:ss.SSS');
        formData.append('timestamp', formattedTime);
        
        const BASE_URL=process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

        fetch(BASE_URL + '/evaluate',
        {
            method: 'POST',
            body: formData
        }).then(response => response.json())
        .catch(error => {
            console.error('Error: ', error);
        })

        handleCloseSusForm()
    }

    return(
        <>
            <Button onClick={() => setShowSusForm(true)}>Bewerten</Button>
            <Modal show={showSusForm} onHide={handleCloseSusForm} dialogClassName="susFormModal">
                <Modal.Header closeButton>
                    <Modal.Title>Bitte bewerte deine Erfahrung mit dem Empfehlungssystem</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmission} encType="multipart/form-data">
                        <Form.Group>
                            <Form.Label>Ich denke ich würde dieses System gerne häufig verwenden</Form.Label>
                            <div key={'inline-radio'} className="mb-3">
                                <Form.Check inline
                                            value="1"
                                            label="1"
                                            name="frequency"
                                            type={'radio'}
                                            onChange={e => handleFrequencyChange(e.target.value)}
                                            id="frequency_1"
                                />
                                <Form.Check inline
                                            value="2"
                                            label="2"
                                            name="frequency"
                                            type={'radio'}
                                            onChange={e => handleFrequencyChange(e.target.value)}
                                            id="frequency_2"
                                />
                                <Form.Check inline
                                            value="3"
                                            label="3"
                                            name="frequency"
                                            type={'radio'}
                                            onChange={e => handleFrequencyChange(e.target.value)}
                                            id="frequency_3"
                                />
                                <Form.Check inline
                                            value="4"
                                            label="4"
                                            name="frequency"
                                            type={'radio'}
                                            onChange={e => handleFrequencyChange(e.target.value)}
                                            id="frequency_4"
                                />
                                <Form.Check inline
                                            value="5"
                                            label="5"
                                            name="frequency"
                                            type={'radio'}
                                            onChange={e => handleFrequencyChange(e.target.value)}
                                            id="frequency_5"
                                />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ich fand das System unnötig komplex</Form.Label>
                            <div key={'inline-radio'} className="mb-3">
                                <Form.Check inline
                                            value="1"
                                            label="1"
                                            name="complexity"
                                            type={'radio'}
                                            onChange={e => handleComplexityChange(e.target.value)}
                                            id="complexity_1"
                                />
                                <Form.Check inline
                                            value="2"
                                            label="2"
                                            name="complexity"
                                            type={'radio'}
                                            onChange={e => handleComplexityChange(e.target.value)}
                                            id="complexity_2"
                                />
                                <Form.Check inline
                                            value="3"
                                            label="3"
                                            name="complexity"
                                            type={'radio'}
                                            onChange={e => handleComplexityChange(e.target.value)}
                                            id="complexity_3"
                                />
                                <Form.Check inline
                                            value="4"
                                            label="4"
                                            name="complexity"
                                            type={'radio'}
                                            onChange={e => handleComplexityChange(e.target.value)}
                                            id="complexity_4"
                                />
                                <Form.Check inline
                                            value="5"
                                            label="5"
                                            name="complexity"
                                            type={'radio'}
                                            onChange={e => handleComplexityChange(e.target.value)}
                                            id="complexity_5"
                                />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ich habe gedacht, dass das System einfach zu verwenden war</Form.Label>
                            <div key={'inline-radio'} className="mb-3">
                                <Form.Check inline
                                            value="1"
                                            label="1"
                                            name="easeOfUse"
                                            type={'radio'}
                                            onChange={e => handleEaseOfUseChange(e.target.value)}
                                            id="easeOfUse_1"
                                />
                                <Form.Check inline
                                            value="2"
                                            label="2"
                                            name="easeOfUse"
                                            type={'radio'}
                                            onChange={e => handleEaseOfUseChange(e.target.value)}
                                            id="easeOfUse_2"
                                />
                                <Form.Check inline
                                            value="3"
                                            label="3"
                                            name="easeOfUse"
                                            type={'radio'}
                                            onChange={e => handleEaseOfUseChange(e.target.value)}
                                            id="easeOfUse_3"
                                />
                                <Form.Check inline
                                            value="4"
                                            label="4"
                                            name="easeOfUse"
                                            type={'radio'}
                                            onChange={e => handleEaseOfUseChange(e.target.value)}
                                            id="easeOfUse_4"
                                />
                                <Form.Check inline
                                            value="5"
                                            label="5"
                                            name="easeOfUse"
                                            type={'radio'}
                                            onChange={e => handleEaseOfUseChange(e.target.value)}
                                            id="easeOfUse_5"
                                />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ich denke, ich bräuchte Unterstützung von einem Experten um dieses System zu verwenden</Form.Label>
                            <div key={'inline-radio'} className="mb-3">
                                <Form.Check inline
                                            value="1"
                                            label="1"
                                            name="technicalSupport"
                                            type={'radio'}
                                            onChange={e => handleTechnicalSupportChange(e.target.value)}
                                            id="technicalSupport_1"
                                />
                                <Form.Check inline
                                            value="2"
                                            label="2"
                                            name="technicalSupport"
                                            type={'radio'}
                                            onChange={e => handleTechnicalSupportChange(e.target.value)}
                                            id="technicalSupport_2"
                                />
                                <Form.Check inline
                                            value="3"
                                            label="3"
                                            name="technicalSupport"
                                            type={'radio'}
                                            onChange={e => handleTechnicalSupportChange(e.target.value)}
                                            id="technicalSupport_3"
                                />
                                <Form.Check inline
                                            value="4"
                                            label="4"
                                            name="technicalSupport"
                                            type={'radio'}
                                            onChange={e => handleTechnicalSupportChange(e.target.value)}
                                            id="technicalSupport_4"
                                />
                                <Form.Check inline
                                            value="5"
                                            label="5"
                                            name="technicalSupport"
                                            type={'radio'}
                                            onChange={e => handleTechnicalSupportChange(e.target.value)}
                                            id="technicalSupport_5"
                                />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ich fand, dass die verschiedenen Funktionen dieses Systems gut integriert waren</Form.Label>
                            <div key={'inline-radio'} className="mb-3">
                                <Form.Check inline
                                            value="1"
                                            label="1"
                                            name="integration"
                                            type={'radio'}
                                            onChange={e => handleIntegrationChange(e.target.value)}
                                            id="integration_1"
                                />
                                <Form.Check inline
                                            value="2"
                                            label="2"
                                            name="integration"
                                            type={'radio'}
                                            onChange={e => handleIntegrationChange(e.target.value)}
                                            id="integration_2"
                                />
                                <Form.Check inline
                                            value="3"
                                            label="3"
                                            name="integration"
                                            type={'radio'}
                                            onChange={e => handleIntegrationChange(e.target.value)}
                                            id="integration_3"
                                />
                                <Form.Check inline
                                            value="4"
                                            label="4"
                                            name="integration"
                                            type={'radio'}
                                            onChange={e => handleIntegrationChange(e.target.value)}
                                            id="integration_4"
                                />
                                <Form.Check inline
                                            value="5"
                                            label="5"
                                            name="integration"
                                            type={'radio'}
                                            onChange={e => handleIntegrationChange(e.target.value)}
                                            id="integration_5"
                                />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ich fand, dass es zu viele Inkonsistenzen in diesem System gab</Form.Label>
                            <div key={'inline-radio'} className="mb-3">
                                <Form.Check inline
                                            value="1"
                                            label="1"
                                            name="inconsistency"
                                            type={'radio'}
                                            onChange={e => handleInconsistencyChange(e.target.value)}
                                            id="inconsistency_1"
                                />
                                <Form.Check inline
                                            value="2"
                                            label="2"
                                            name="inconsistency"
                                            type={'radio'}
                                            onChange={e => handleInconsistencyChange(e.target.value)}
                                            id="inconsistency_2"
                                />
                                <Form.Check inline
                                            value="3"
                                            label="3"
                                            name="inconsistency"
                                            type={'radio'}
                                            onChange={e => handleInconsistencyChange(e.target.value)}
                                            id="inconsistency_3"
                                />
                                <Form.Check inline
                                            value="4"
                                            label="4"
                                            name="inconsistency"
                                            type={'radio'}
                                            onChange={e => handleInconsistencyChange(e.target.value)}
                                            id="inconsistency_4"
                                />
                                <Form.Check inline
                                            value="5"
                                            label="5"
                                            name="inconsistency"
                                            type={'radio'}
                                            onChange={e => handleInconsistencyChange(e.target.value)}
                                            id="inconsistency_5"
                                />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ich könnte mir vorstellen, dass die meisten Studierenden schnell mit diesem System umzugehen lernen</Form.Label>
                            <div key={'inline-radio'} className="mb-3">
                                <Form.Check inline
                                            value="1"
                                            label="1"
                                            name="learnability"
                                            type={'radio'}
                                            onChange={e => handleLearnabilityChange(e.target.value)}
                                            id="learnability_1"
                                />
                                <Form.Check inline
                                            value="2"
                                            label="2"
                                            name="learnability"
                                            type={'radio'}
                                            onChange={e => handleLearnabilityChange(e.target.value)}
                                            id="learnability_2"
                                />
                                <Form.Check inline
                                            value="3"
                                            label="3"
                                            name="learnability"
                                            type={'radio'}
                                            onChange={e => handleLearnabilityChange(e.target.value)}
                                            id="learnability_3"
                                />
                                <Form.Check inline
                                            value="4"
                                            label="4"
                                            name="learnability"
                                            type={'radio'}
                                            onChange={e => handleLearnabilityChange(e.target.value)}
                                            id="learnability_4"
                                />
                                <Form.Check inline
                                            value="5"
                                            label="5"
                                            name="learnability"
                                            type={'radio'}
                                            onChange={e => handleLearnabilityChange(e.target.value)}
                                            id="learnability_5"
                                />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ich fand das System sehr mühsam zu bedienen</Form.Label>
                            <div key={'inline-radio'} className="mb-3">
                                <Form.Check inline
                                            value="1"
                                            label="1"
                                            name="cumbersome"
                                            type={'radio'}
                                            onChange={e => handleCumbersomeChange(e.target.value)}
                                            id="cumbersome_1"
                                />
                                <Form.Check inline
                                            value="2"
                                            label="2"
                                            name="cumbersome"
                                            type={'radio'}
                                            onChange={e => handleCumbersomeChange(e.target.value)}
                                            id="cumbersome_2"
                                />
                                <Form.Check inline
                                            value="3"
                                            label="3"
                                            name="cumbersome"
                                            type={'radio'}
                                            onChange={e => handleCumbersomeChange(e.target.value)}
                                            id="cumbersome_3"
                                />
                                <Form.Check inline
                                            value="4"
                                            label="4"
                                            name="cumbersome"
                                            type={'radio'}
                                            onChange={e => handleCumbersomeChange(e.target.value)}
                                            id="cumbersome_4"
                                />
                                <Form.Check inline
                                            value="5"
                                            label="5"
                                            name="cumbersome"
                                            type={'radio'}
                                            onChange={e => handleCumbersomeChange(e.target.value)}
                                            id="cumbersome_5"
                                />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ich fühlte mich sehr sicher im Umgang mit dem System</Form.Label>
                            <div key={'inline-radio'} className="mb-3">
                                <Form.Check inline
                                            value="1"
                                            label="1"
                                            name="confidence"
                                            type={'radio'}
                                            onChange={e => handleConfidenceChange(e.target.value)}
                                            id="confidence_1"
                                />
                                <Form.Check inline
                                            value="2"
                                            label="2"
                                            name="confidence"
                                            type={'radio'}
                                            onChange={e => handleConfidenceChange(e.target.value)}
                                            id="confidence_2"
                                />
                                <Form.Check inline
                                            value="3"
                                            label="3"
                                            name="confidence"
                                            type={'radio'}
                                            onChange={e => handleConfidenceChange(e.target.value)}
                                            id="confidence_3"
                                />
                                <Form.Check inline
                                            value="4"
                                            label="4"
                                            name="confidence"
                                            type={'radio'}
                                            onChange={e => handleConfidenceChange(e.target.value)}
                                            id="confidence_4"
                                />
                                <Form.Check inline
                                            value="5"
                                            label="5"
                                            name="confidence"
                                            type={'radio'}
                                            onChange={e => handleConfidenceChange(e.target.value)}
                                            id="confidence_5"
                                />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ich musste viele Dinge lernen bevor ich anfangen konnte dieses System zu verwenden</Form.Label>
                            <div key={'inline-radio'} className="mb-3">
                                <Form.Check inline
                                            value="1"
                                            label="1"
                                            name="preparationNeeded"
                                            type={'radio'}
                                            onChange={e => handlePreparationNeededChange(e.target.value)}
                                            id="preparationNeeded_1"
                                />
                                <Form.Check inline
                                            value="2"
                                            label="2"
                                            name="preparationNeeded"
                                            type={'radio'}
                                            onChange={e => handlePreparationNeededChange(e.target.value)}
                                            id="preparationNeeded_2"
                                />
                                <Form.Check inline
                                            value="3"
                                            label="3"
                                            name="preparationNeeded"
                                            type={'radio'}
                                            onChange={e => handlePreparationNeededChange(e.target.value)}
                                            id="preparationNeeded_3"
                                />
                                <Form.Check inline
                                            value="4"
                                            label="4"
                                            name="preparationNeeded"
                                            type={'radio'}
                                            onChange={e => handlePreparationNeededChange(e.target.value)}
                                            id="preparationNeeded_4"
                                />
                                <Form.Check inline
                                            value="5"
                                            label="5"
                                            name="preparationNeeded"
                                            type={'radio'}
                                            onChange={e => handlePreparationNeededChange(e.target.value)}
                                            id="preparationNeeded_5"
                                />
                            </div>
                        </Form.Group>
                        <Button variant="success" type="submit" className='m-1'>Bestätigen</Button>
                        <Button variant="secondary" onClick={handleCloseSusForm}>Abbrechen</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default SusFormWidget
