import React, { useState } from 'react';
import RecommendationInformation from './recommendationInformation';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

function RecommendationInformationWidget() {
    const [open, setOpen] = useState(true);

    return (
        <div id="information-widget">
            <Button
            className="rounded-circle"
            onClick={() => setOpen(!open)}
            aria-controls="recommendation-information"
            aria-expanded={open}>
                ?
            </Button>
            <Collapse in={open}>
                <div id="recommendation-information">
                    <RecommendationInformation />
                </div>
            </Collapse>
        </div>
    )
}

export default RecommendationInformationWidget
