import React, { useState } from 'react';
import RecommendationExplanationTable from '../components/recommendationExplanationTable';
import RecommendationOverview from '../components/recommendationOverview';
import RecommendationInformationWidget from '../components/recommendationInformationWidget';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SusFormWidget from '../components/susFormWidget';
import { useDispatch, useSelector } from 'react-redux';
import {updateRecommendationOverview, updateRecommendationSemester, selectUserId} from '../../redux/features/recommendation/recommendationSlice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function RecommendationPage() {
    const BASE_URL=process.env.REACT_APP_BASE_URL || 'http://localhost:5001';
    const dispatch = useDispatch();
    const [semester, setSemester] = useState(useSelector(state => state.recommendation.semester));
    const [showSemesterSelectionModal, setShowSemesterSelectionModal] = useState(false);
    const currentUserId = useSelector(selectUserId);

    const handleCloseModal = () => setShowSemesterSelectionModal(false);
    const handleShowModal = () => setShowSemesterSelectionModal(true);

    const handleSemesterSelectionChange = (input) => {
        setSemester(input);
        dispatch(updateRecommendationSemester(input));
    }

    const handleSubmission = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('semester', semester);
        formData.append('user_id', currentUserId);

        fetch(BASE_URL + '/getRecommendations', 
        {
            method: 'POST',
            body: formData
        }).then(response => response.json())
        .then((result) => {
                dispatch(updateRecommendationOverview(result));
                handleCloseModal();
            }
        ).catch((error) => {console.error('Error:', error)})
    }

    return (
        <Container>
            <Row>
                <Col md={10}>
                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey='0'>
                            <Accordion.Header>Deine Empfehlungen für das {semester}. Semester</Accordion.Header>
                            <Accordion.Body>
                                <Button onClick={handleShowModal}>Semesterauswahl</Button>
                                <RecommendationOverview />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='1'>
                            <Accordion.Header>Details zu deinen Empfehlungen</Accordion.Header>
                            <Accordion.Body><RecommendationExplanationTable /> </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
                <Col md={2}>
                    <RecommendationInformationWidget />
                </Col>
            </Row>
            <Row>
                <Col md={2} className='mt-1'>
                    <SusFormWidget></SusFormWidget>
                </Col>
            </Row>
            <Modal show={showSemesterSelectionModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Für welches Semester möchtest du unsere Empfehlung sehen?</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ms-auto">
                    <input type="number" min="2" max="20" value={semester} onChange={e => handleSemesterSelectionChange(e.target.value)}></input>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" type="submit" onClick={handleSubmission}>Akzeptieren</Button>
                    <Button variant="secondary" onClick={handleCloseModal}>Abbrechen</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default RecommendationPage
