import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from "react-router-dom";
import React, {useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {updateRecommendationOverview, updateUserId, selectUserId} from '../../redux/features/recommendation/recommendationSlice';
import {useDropzone} from 'react-dropzone';
import {AiOutlineUpload} from 'react-icons/ai'

function UploadPage() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const BASE_URL=process.env.REACT_APP_BASE_URL || 'http://localhost:5001';
    const currentUserId = useSelector(selectUserId);
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
    
    const files = acceptedFiles.map(file => (
        <li key={file.path}>
          {file.path}
        </li>
        )
    );


    useEffect(() => {
        // Fetch user ID when the component mounts
        fetch(`${BASE_URL}/generate_user_id`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                dispatch(updateUserId(data.user_id));
            })
            .catch(error => {
                console.error('Error fetching user ID', error);
            });
    }, [BASE_URL, dispatch]);  // Include BASE_URL and dispatch in the dependency array

    function handleSubmission(e) {
        e.preventDefault();
        const formData = new FormData();
        acceptedFiles.forEach(file => formData.append('file', file));
        formData.append('user_id', currentUserId);

        fetch(BASE_URL + '/upload',
        {
            method: 'POST',
            body: formData,
        }
        ).then(() => {
            fetch(
                BASE_URL + '/getRecommendations',
                {
                    method: 'POST',
                    body: formData
                }
            ).then(response => response.json())
            .then((result) => {
                    dispatch(updateRecommendationOverview(result));
                    navigate('/recommendation');
                }
            ).catch((error) => {console.error('Error:', error)})
        })
    };

    return (
        <>
            <section>
                <div {...getRootProps({className: 'dropzone'})} id="upload-area">
                    <input {...getInputProps()} />
                    <p>Lade deine Studiendokumentation hoch </p>
                    <AiOutlineUpload size={70}/>
                    <aside>
                        {files}
                    </aside>
                </div>
                <p>Wir benötigen deine Studiendokumentation, welche du <a href='https://polli.bht-berlin.de' target="_blank" rel="noreferrer">hier</a> herunterladen kannst.</p>
                {acceptedFiles.length > 0 && <Button id="upload-button" variant="success" onClick={handleSubmission}>Upload</Button>}
            </section>
        </>
    );
}

export default UploadPage
