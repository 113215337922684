import React from 'react'
import Card from 'react-bootstrap/Card';

function RecommendationInformation() {

    return (
        <Card>
            <Card.Body>
                <div className="information-text" id="information-text" style={{"fontSize": "13px"}}>
                    <p>Wir vergleichen deine Ergebnisse mit denen anderer Studierender, die deinen Studiengang in der Vergangenheit erfolgreich abgeschlossen haben.
                        Basierend auf Ähnlichkeiten zwischen dir und diesen Studierenden empfehlen wir dir eine Auswahl an Kursen mit dem Ziel, dir dabei zu helfen dein Studium erfolgreich abzuschließen.
                    </p>
                    <p>In dem Fenster mit den Details zu deinen Empfehlungen kannst du die Ergebnisse der fünf Studierenden sehen die deinem bisherigen Studienverlauf am Ähnlichsten sind. Die Kurse die wir empfehlen sind die, die die Mehrheit dieser Studierenden, in dem entsprechenden Semester erfolgreich abgeschlossen haben.</p>
                    <p>Weitere Informationen zum verwendeten Algorithmus findest du hier <a href='https://de.wikipedia.org/wiki/N%C3%A4chste-Nachbarn-Klassifikation' target="_blank" rel="noreferrer">hier</a>.</p>
                </div>
            </Card.Body>
        </Card>
    )
}

export default RecommendationInformation
