import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  recommendationOverview: [],
  semester: 2,
  user_id: ''
}

export const recommendationSlice = createSlice({
    name: 'recommendation',
    initialState,
    reducers: {
        updateRecommendationOverview: (state, action) => {
            state.recommendationOverview = action.payload
        },
        updateRecommendationSemester: (state, action) => {
            state.semester = action.payload
        },
        updateUserId: (state, action) => {
            state.user_id = action.payload
        }
    }
})

export const {updateRecommendationOverview, updateRecommendationSemester, updateUserId} = recommendationSlice.actions
export const selectUserId = (state) => state.recommendation.user_id;

export default recommendationSlice.reducer
