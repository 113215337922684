import React from 'react'
import { useSelector} from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/esm/ListGroupItem';

function RecommendationOverview() {
    const recommendationOverview = useSelector(state => state.recommendation.recommendationOverview);
    const recommendedModules = recommendationOverview.recommended_modules !== undefined ? recommendationOverview.recommended_modules : [];
    const module_titles = recommendationOverview.module_titles;

    return (
        <ListGroup className="list-group-flush" id="recommendation-overview" style={{"textAlign": "left"}}>
            {recommendedModules.map(module => <ListGroupItem >{module}: {module_titles[module]}</ListGroupItem>)}
        </ListGroup>
    )
}

export default RecommendationOverview
