import React from 'react'
import { useSelector } from 'react-redux'
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

function RecommendationExplanationTable() {
    const recommendationOverview = useSelector(state => state.recommendation.recommendationOverview);
    const moduleTitles = recommendationOverview.module_titles;
    const recommendedModules = recommendationOverview.recommended_modules;
    const semesters = [];

    for (const [key, value] of Object.entries(recommendationOverview.students[0].semesters)) {
        semesters.push(value);
    }
    let courseCount = 0;
    semesters.map(semester => courseCount += semester.length);

    const students = [];
    recommendationOverview.students.map(student => {
        for (const [key, value] of Object.entries(student.semesters)) {
            students.push(value);
        }
    })
    const rows = [];
    for (let i = 0; i < students.flat().length; i += courseCount) {
        const row = students.flat().slice(i, i + courseCount);
        rows.push(row);
    }

    const renderTooltip = (module_title, props) => (    //add hover over tooltip to courses in table
        <Tooltip id="course-tooltip" {...props}>
            {module_title}
        </Tooltip>
    );

    return (
        <div className="page-content" id="recommendationPage">
            <Table variant="light" bordered size='sm' responsive>
                <thead>
                    <tr>
                        <th colSpan={1}>Semester</th>
                        {semesters.map((semester, index) => <th colSpan={semester.length}>{index + 1}</th>)}

                    </tr>
                </thead>
                <tbody id='recommendation-table'>
                    <tr colSpan={courseCount}>
                        <td>Kurs</td>
                        {semesters.map(semester => semester.map(course =>   //create one column per course per semester and highlight them if they are reocmmended
                            <td className={`${recommendedModules.includes(course.module_id) ? "bg-info" : ""}`}>
                                <OverlayTrigger placement='top' overlay={renderTooltip(moduleTitles[course.module_id])}>
                                    <span>{course.module_id.replace("M", "B").replace("E", "WP")}</span>
                                </OverlayTrigger>
                            </td>))}
                    </tr>

                    {rows.map(
                        (student, rowIndex) =>
                            <tr colSpan={rowIndex === 0 ? courseCount + 1 : courseCount}>
                                {rowIndex === 0 ? <td id='personal-grades'>Deine Noten</td> : <td>Studi {rowIndex}</td>}
                                {student.map((course, index) => <td style={ //create table row element with color based on contained grade
                                        course.grade > 4 ?
                                        course.grade > 5 ?
                                        course.grade > 6 ?
                                        {"backgroundColor": process.env.REACT_APP_UNATTEMPTED_COURSE_COLOR} :
                                        {"backgroundColor": process.env.REACT_APP_ATTEMPTED_COURSE_UNATTEMPTED_EXAM_COLOR} :
                                        {"backgroundColor": process.env.REACT_APP_FAILED_COURSE_COLOR} :
                                        {"backgroundColor": process.env.REACT_APP_PASSED_COURSE_COLOR}
                                    }>

                                        {
                                            course.grade === 7 ? "":
                                            index < (courseCount - semesters[semesters.length-1].length) ? course.grade : ""
                                        }
                                </td>)}
                            </tr>
                    )}
                </tbody>
            </Table>
            {rows.some(row => {return row.some(item => {return item.grade === 6})}) && <p>Mit 6 sind Kurse bewertet, die in dem Semester zwar belegt wurden, aber die Prüfung nicht versucht wurde.</p>}
            
            <div style={{ display: 'flex' }}>
                <div className="legend-item">
                    <div style={{ width: '125px', height: '20px', backgroundColor: process.env.REACT_APP_FAILED_COURSE_COLOR, marginRight: '5px' }}></div>
                    <span style={{ marginRight: '5px', alignSelf: 'center' }}>Nicht bestanden</span>
                </div>

                <div className="legend-item">
                    <div style={{ width: '82px', height: '20px', backgroundColor: process.env.REACT_APP_PASSED_COURSE_COLOR, marginRight: '5px', marginLeft: '10px' }}></div>
                    <span style={{ marginRight: '5px', marginLeft: '10px' }}>Bestanden</span>
                </div>

                <div className="legend-item">
                    <div style={{ width: '160px', height: '20px', backgroundColor: process.env.REACT_APP_UNATTEMPTED_COURSE_COLOR, marginLeft: '10px' }}></div>
                    <span style={{marginLeft: '10px' }}>Nicht teilgenommen <style></style></span>
                </div>
            </div>
        </div>
    )
}

export default RecommendationExplanationTable
